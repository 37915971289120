/* eslint-disable @next/next/no-img-element */
import classNames from 'classnames';
import { AdFragment } from '@amf/shared/types/graphql';
import { AdType } from '@amf/shared/utils/ad';

interface AdProps {
  ad: AdFragment;
}

export default function Ad({ ad }: AdProps) {
  if (ad.imageUrl === null) {
    return null;
  }

  return (
    <div
      className={classNames('Ad', {
        'Ad--wide': ad.type === AdType.homepageWide,
        'Ad--video': ad.type === AdType.homepageVideo,
        'Ad--square': ad.type === AdType.homepageSquare,
      })}
    >
      <a href={ad.link} target='_blank' rel='noreferrer'>
        <img src={ad.imageUrl} alt={ad.link} unselectable={'on'} style={{ width: '100%' }} />
      </a>
    </div>
  );
}
