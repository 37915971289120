import classNames from 'classnames';
import { AdFragment } from '@amf/shared/types/graphql';

import Ad from './Ad';

type Props = {
  wide: AdFragment[];
  video: AdFragment[];
  square: AdFragment[];
  className?: string;
};

function Ads({ wide, video, square, className }: Props) {
  return (
    <div className={classNames('AdList', className)}>
      {video.map(ad => (
        <Ad key={ad.id} ad={ad} />
      ))}
      {square.map(ad => (
        <Ad key={ad.id} ad={ad} />
      ))}
      {wide.map(ad => (
        <Ad key={ad.id} ad={ad} />
      ))}
    </div>
  );
}

export default Ads;
